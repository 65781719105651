import { NavLink as RemixNavLink, type NavLinkProps } from 'react-router'
import { getHref } from '../internal-link/link.helper'
import { useClientLang } from '~/utils/i18n.utils'

export const NavLink: React.FC<NavLinkProps> = ({ ...props }) => {
	const lang = useClientLang()

	return (
		<RemixNavLink {...props} to={getHref(props.to, lang.lngShort)}>
			{props.children}
		</RemixNavLink>
	)
}
NavLink.displayName = 'NavLink'
